import { useEffect, useState } from "react";
import { isLaptop, isPc } from "../functions";
import { useTranslation } from "react-i18next";
import BackToTop from "./BackToTop";

function Blog(props : any) {

    const { t } = useTranslation();
    const init = null;
    const [scrollY, setScrollY] = useState(0);
    const [lastArticle, setLastArticle] = useState<{title: string, slug: string, image: string}|null>(null)
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      fetch("https://codelabback.devexploris.com/getLastArticles").then((response) => {
        return response.json();
      }).then((data) => {
        if(data.length > 0) {
          setLastArticle({title: data[0][0].title, slug: data[0][0].slug, image: data[0][0].path});
        }
      });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    return (
        <div className="fullscreen flex-center flex-col" id="blog">
            <h2 className={`title rotate-out ${scrollY > props.delta ? 'rotate-in' : ''}`}><img src={process.env.PUBLIC_URL + "/icons/blog.webp"} alt={t('blog')} /> {t('blog')} <img src={process.env.PUBLIC_URL + "/icons/blog.webp"} alt={t('blog')} /></h2>
            <p className={`fs-p slide-out-right ${scrollY > props.delta + 150  ? 'slide-in' : ''}`}>
            {t('firstTextBlog')} <a className="link underline" href="https://codelab.devexploris.com" target="_blank">CodeLab</a>, {t('secondTextBlog')} 
            </p>
            {lastArticle !== null &&
            <div className={`box blog-last-article slide-out-left ${scrollY > props.delta ? 'slide-in' : ''}`}>
              <div className="infos">
                <span>{t('myLastArticle')}</span>
                <span >{lastArticle!.title}</span>
                <a className="link-blog link-desktop underline" href={`https://codelab.devexploris.com/article/${lastArticle.slug}`} target="_blank">{t('discover')}</a>
              </div>
              <div>
                <img className="" src={`https://codelabback.devexploris.com/images/${lastArticle.image}`} />
              </div>
              <a className="link-blog link-mobile underline" href={`https://codelab.devexploris.com/article/${lastArticle.slug}`} target="_blank">{t('discover')}</a>
            </div>
            }
            <BackToTop pathname="/" scrollY={scrollY} delta={props.delta} />
        </div>
    );

}

export default Blog;