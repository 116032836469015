import { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import ChibiProject from "./ChibiProject";
import { isLaptop, isPc } from "../functions";
import { useTranslation } from "react-i18next";
import BackToTop from "./BackToTop";

function Projects(props : any) {

    const { t } = useTranslation();

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const projects = [
        {
            name: "Escape Orbit",
            languages: ["sass", "javascript"],
            image: "escapeOrbit_one",
            url: "https://escapeorbit.netlify.app",
            description: t('projectEscapeOrbit')
        },
        {
            name: "Crepe Event",
            languages: ["reactJS", "php"],
            image: "crepevent_one",
            url: "https://www.crepe-event.fr",
            description: t('projectCrepeEvent')
        },
        {
            name: "Mercadona",
            languages: ["sass", "javascript", "symfony"],
            image: "mercadona_one",
            url: "https://studi-mercadona.devexploris.com/",
            description: t('projectMercadona')
        },
        {
            name: "World Excape",
            languages: ["sass", "reactJS"],
            image: "worldExcape_one",
            url: "https://world-excape.devexploris.com/",
            description: t('projectWorldExcape')
        },
        {
            name: "CodeLab",
            languages: ["ReactJS", "sass", "symfony"],
            image: "blog",
            url: "https://codelab.devexploris.com/",
            description: t('blog')
        },
    ];
    
    const [dialog, setDialog] = useState('');

    function handleInfo(desc : string) {
      setDialog(desc);
    }

    function handleClick(url : string) {
      window.open(url, '_blank');
    }

    return (
        <div className="fullscreen flex-center flex-col" id="projects">
            <h2 className={`title rotate-out ${scrollY > props.delta ? 'rotate-in' : ''}`}><img src={process.env.PUBLIC_URL + "/icons/projects.webp"} alt={t('projects')} /> {t('projects')} <img src={process.env.PUBLIC_URL + "/icons/projects.webp"} alt={t('projects')} /></h2>
            <div className={`box projectBox slide-out-right ${scrollY > props.delta + 50 ? 'slide-in' : ''}`}>
            <Carousel statusFormatter={() => ''} emulateTouch={true} useKeyboardArrows={true} swipeable={true}>
              {projects.map((project : any, index: number) => {
                return(
                  <div key={index} onDoubleClick={() => handleClick(project.url)} onClick={window.innerWidth < 768 ? () => handleClick(project.url) : () => handleInfo(project.description)}>
                    <div className="projectTool">
                      {project.languages.map((language : string, index : number) => {
                        return(
                          <img src={process.env.PUBLIC_URL + "/tools/" + language + '.webp'} key={index} alt={language} loading="lazy" />
                        );
                      })}
                    </div>
                    <img className="projectPic" src={process.env.PUBLIC_URL + '/projects/' + project.image + '.webp'} loading="lazy" />
                  </div>
                );
              })}
            </Carousel>
            </div>
            <ChibiProject deltaBegin={isPc() ? 1650 : isLaptop() ? 2300 : 2800} deltaEnd={isPc() ? 1650 : isLaptop() ? 2300 : 2800} dialog={dialog}/>
        </div>
    );

}

export default Projects;