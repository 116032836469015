import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Tools(props : any) {

    const { t } = useTranslation();

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    const logos = ["javascript", "reactJS", "php", "symfony", "sass", "bootstrap", "mysql", "git", "linux", "fedora", "apache", "nginx"];

    return (
        <div className="fullscreen flex-center flex-col" id="tools">
            <h2 className={`title rotate-out ${scrollY > props.delta ? 'rotate-in' : ''}`}><img src={process.env.PUBLIC_URL + "/icons/tools.webp"} alt={t('toolbox')} /> {t('toolbox')} <img src={process.env.PUBLIC_URL + "/icons/tools.webp"} alt={t('toolbox')} /></h2>
            <div className={`box toolbox slide-out-left ${scrollY > props.delta + 250 ? 'slide-in' : ''}`}>
                {logos.map((logo: string, index: number) => {
                    return(
                        <div className="tool" key={index}>
                            <img src={process.env.PUBLIC_URL + '/tools/' + logo + '.webp'} alt={"logo " + logo} loading="lazy"/>
                            <span className="text-info fw-bold">{logo.charAt(0).toUpperCase() + logo.slice(1)}</span>
                        </div>
                    );
                })}
            </div>
        </div>
        
    );

}

export default Tools;